import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

require('../../stylesheets/components/layout/_Modal.scss');

class Modal extends React.Component {

    constructor() {
        super();
        this.modalHandler = (e) => {
            this.setState({
                data: e.detail.data,
                visible: true
            });
        };
        this.state = {
            data: {
                title: '',
                closeOnClick: false,
                content: ''
            },
            visible: false
        };
        this.close = this.close.bind(this);
        this.modalClick = this.modalClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('modal', this.modalHandler);
    }
    componentWillUnmount() {
        document.removeEventListener('modal', this.modalHandler);
    }

    render() {
        if(this.state.visible){
            document.title = process.env.REACT_APP_SITE_NAME + ' | ' + this.state.data.title;
        }else{
            document.title = process.env.REACT_APP_SITE_NAME;
        }
        return !this.state.visible ? null : <div className={'modal'} onClick={this.modalClick}>
            <div className={'modal__container'}>
                <div className={'modal__titleBar'}>
                    <span className={'modal_title'}>{ this.state.data.title }</span>
                    <span className={'modal__closeButton'} onClick={this.close}>
                        <FontAwesomeIcon icon={['fal','times']}/>
                    </span>
                </div>
                <div className={'modal__content'}>
                    {this.state.data.content}
                </div>
            </div>
            <div className={'modal__backdrop'} />
        </div>
    }

    close() {
        this.setState({
            visible: false,
            data: {
                title: '',
                closeOnClick: false,
                content: ''
            }
        });
    }

    static show(data) {
        document.dispatchEvent(new CustomEvent('modal', {
            detail: {
                data
            }
        }));
    }

    modalClick() {
        if (this.state.data.closeOnClick) this.close();
    }
}
export default Modal;
