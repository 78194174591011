import React from 'react';
import Header from './layout/Header';
import Intro from './layout/Intro';
import Filters from './filters/Filters';
import Bar from './layout/Bar';
import DomainList from './results/DomainList';
import Footer from './layout/Footer';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHeart, faFilter, faUserAstronaut, faAngleDown, faSortAmountDown, faFileExport, faPersonDollyEmpty, faSearch, faTimes, faTheaterMasks, faEnvelopeOpenText, faSyncAlt, faSort, faCogs, faCookieBite } from '@fortawesome/pro-light-svg-icons';
import { faTimesCircle, faSortUp, faSortDown, faArrowAltUp, faCheck, faUser, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { faInfinity } from '@fortawesome/pro-regular-svg-icons';
import { faTwitter, faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import Modal from "./layout/Modal";
import GoToTop from './layout/partials/GoToTop';
import ReactGA from 'react-ga';

//ReactGA.initialize('UA-136632719-1');
ReactGA.pageview(window.location.pathname + window.location.search);

library.add(faTwitter, faFacebook, faInstagram, faYoutube, faHeart, faFilter, faUserAstronaut, faUser, faAngleDown, faSortAmountDown, faFileExport, faPersonDollyEmpty, faSearch, faTimes, faTheaterMasks, faEnvelopeOpenText, faTimesCircle, faSyncAlt, faSortUp, faSortDown, faSort, faCogs, faCookieBite, faArrowAltUp, faInfinity, faCheck, faCaretDown );

require('../stylesheets/base.scss');

const App = () => {
    return (
        <div className={'page'}>
            <Header />
            <Intro />
            <main className={'page__content'}>
                <Filters />
                <Bar />
                <DomainList />
            </main>
            <Footer />
            <Modal/>
            <GoToTop />
        </div>
    );
};

export default App;
