import { combineReducers } from 'redux';
import domainsReducer from './domainsReducer';
import sortingReducer from './sortingReducer';
import filtersReducer from './filtersReducer';
import modeReducer from './modeReducer';
import completedReducer from './completedReducer';
import optionsReducer from './optionsReducer';

export default combineReducers({
    domains: domainsReducer,
    sorting: sortingReducer,
    filters: filtersReducer,
    mode: modeReducer,
    options: optionsReducer,
    completed: completedReducer
});
