import React from 'react';

class Tab extends React.Component {

    onClick = () => {
        const { label, onClick } = this.props;
        onClick(label);
    };

    render() {
        const {
            onClick,
            props: {
                activeTab,
                label,
                className
            },
        } = this;

        let applyClassName = 'filters__tabs__titles__item';

        if (activeTab === label) {
            applyClassName += ' filters__tabs__titles__item--active';
        }

        if(className) {
            applyClassName += ' ' + className;
        }

        return (
            <li
                className={applyClassName}
                onClick={onClick}
            >
                {label}
            </li>
        );
    }
}

export default Tab;
