import React from 'react';
import BaseFilter from './BaseFilter';
import InputText from './presentation/InputText';
import Label from "./presentation/Label";

class Keyword extends BaseFilter {

    render() {
        return (
            <div className={'filter__item filter__item--keyword filter__item--input'}>
                <div className={'filter__item__wrapper'}>
                    <Label
                        for={this.props.id}
                        text={this.props.label}
                    />
                    <InputText
                        id={this.props.id}
                        onChange={this.changeHandler}
                        value={this.props.value ? this.props.value : ''}
                        label={this.props.label}
                        icon={['fal','search']}
                        resetable
                    />
                </div>
            </div>
        );
    }
}

export default Keyword;
