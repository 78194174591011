import React from 'react';
import ReactGA from 'react-ga';

import Tab from './partials/Tab';

class Tabs extends React.Component {
    /*static propTypes = {
        children: PropTypes.instanceOf(Array).isRequired,
    }*/

    constructor(props) {
        super(props);

        if (Array.isArray(this.props.children)) {
            this.state = {
                activeTab: this.props.children[0].props.label,
            };
        } else {
            this.state = {
                activeTab: this.props.children.props.label,
            };
        }
    }

    onClickTabItem = (tab) => {
        if (tab !== this.state.activeTab) {
            ReactGA.event({
                category: 'Filters',
                action: 'Tab Changed',
                label: tab
            });
        }

        this.setState({ activeTab: tab });
    };

    render() {
        const {
            onClickTabItem,
            props: {
                children,
            },
            state: {
                activeTab,
            }
        } = this;

        if (Array.isArray(this.props.children)) {
            return (
                <div className="filter__tabs">
                    <ol className="filters__tabs__titles">
                        {children.map((child) => {
                            const { label, className } = child.props;

                            return (
                                <Tab
                                    activeTab={activeTab}
                                    key={label}
                                    label={label}
                                    className={className}
                                    onClick={onClickTabItem}
                                />
                            );
                        })}
                    </ol>
                    <div className={'filters__tabs__contents filters__tabs__contents--' + activeTab}>
                        {children.map((child) => {
                            if (child.props.label !== activeTab) return undefined;
                            return child.props.children;
                        })}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="filters__tabs">
                    <ol className="filters__tabs__titles">
                        <Tab
                            activeTab={activeTab}
                            key={this.props.children.props.label}
                            label={this.props.children.props.label}
                            className={this.props.children.props.className}
                            onClick={onClickTabItem}
                        />
                    </ol>
                    <div className={'filters__tabs__contents filters__tabs__contents--' + this.props.children.props.label}>
                            { this.props.children.props.children }
                    </div>
                </div>
            );
        }
    }
}

export default Tabs;
