import React from 'react';
import Keyword from './Keyword';
import DomainLength from './DomainLength';
import MajesticBacklinks from './MajesticBacklinks';
import DomainAge from './DomainAge';
import MajesticDomainPop from './MajesticDomainPop';
import Excludes from './Excludes';
import GoogleSearchVolume from './GoogleSearchVolume';
import GoogleCPC from './GoogleCPC';
import ParkingViews from './ParkingViews';
import Tlds from './Tlds';
import { connect } from "react-redux";
import { filterChanged, fetchDomains } from "../../actions/index";
import Tabs from "../layout/Tabs";
import _ from 'lodash';
import Button from "./presentation/Button";
import ReactGA from 'react-ga';

require('../../stylesheets/components/filters/_Filters.scss');

class Filters extends React.Component {

    constructor() {
        super();
        this.buildFilterPostObject = _.debounce(this.buildFilterPostObject, 1000);
        this.trackFilter = _.debounce(this.trackFilter, 1200);
    }

    trackFilter = (data) => {
        ReactGA.event(data);
    };

    resetHandler = () => {
        this.trackFilter({
            category: 'Filters',
            action: 'Filters Reset'
        });

        this.props.filterChanged({'action': 'reset'});
    };

    changeHandler = event => {
        if (Object.keys(event).length === 1) { //range
            this.trackFilter({
                category: 'Filters',
                action: 'Filter Changed',
                label: Object.keys(event)[0]
            });

            this.props.filterChanged({
                'id': [Object.keys(event) + '_FROM'],
                'value': Object.values(event)[0][0]
            });

            if(Object.values(event)[0][1] !== ''){
                this.props.filterChanged({
                    'id': [Object.keys(event) + '_TO'],
                    'value': Object.values(event)[0][1]
                });
            }else{
                this.props.filterChanged({
                    'id': [Object.keys(event) + '_TO'],
                    'value': undefined
                });
            }
        } else if (event.target.type === 'checkbox' && !event.target.checked) { //checkbox
            this.trackFilter({
                category: 'Filters',
                action: 'Filter Changed',
                label: event.target.id
            });

            this.props.filterChanged({
                'id': event.target.id,
                'value': ''
            });
        } else { //default input
            this.trackFilter({
                category: 'Filters',
                action: 'Filter Changed',
                label: event.target.id
            });

            this.props.filterChanged(event.target);
        }
    };

    componentDidUpdate(prevProps) {

        let modeChanged = false;
        let sortChanged = false;

        if (prevProps.mode !== this.props.mode) {
            modeChanged = true;
            if (this.props.mode === 'basic') {
                this.props.filterChanged({'action': 'reset'});
            }
        }

        if (
            prevProps.sorting.sortBy !== this.props.sorting.sortBy ||
            prevProps.sorting.sortDirection !== this.props.sorting.sortDirection
        ) {
            ReactGA.event({
                category: 'Filters',
                action: 'Sorting Changed',
                label: this.props.sorting.sortBy + ',' + this.props.sorting.sortDirection
            });

            sortChanged = true;
        }

        if (!sortChanged && !modeChanged) {
            this.buildFilterPostObject(this.props.filters, this.props.sorting);
        }
    }

    buildFilterPostObject(filters, sorting) {
        this.props.fetchDomains(filters, sorting);
    };

    renderResetButton = () => {
        return (
            <Button
                type={'switch'}
                classes={'btn--transparent btn--smaller btn--reset'}
                label={'Reset Filters'}
                iconStart={['fas','times-circle']}
                iconEnd
                onClick={this.resetHandler}
            />
        )
    };

    renderExpertFilters = () => {
        return (
            <Tabs>
                <section label="General">
                    <Keyword
                        id="KEYWORD"
                        label="Keyword"
                        onChange={this.changeHandler}
                    />
                    <DomainLength
                        id="LENGTH"
                        label="Domain Length"
                        onChange={this.changeHandler}
                        labelLower="Characters"
                    />
                    <Tlds
                        id="TLDS"
                        label="TLD Selection"
                        tlds={this.props.filters.TLDS}
                        onChange={this.changeHandler}
                    />
                    <Excludes
                        ids={[
                            { label: 'with hyphens', value: '0', id: "HYPHENS" },
                            { label: 'with IDN', value: 'false', id: "IDN" },
                            { label: 'with numbers', value: '0', id: "DIGITS" }
                        ]}
                        label="idn domains only"
                        onChange={this.changeHandler}
                        title="Exclude Domains"
                    />
                </section>
                <section label="Facts" className="new-label">
                    <GoogleSearchVolume
                        id="GOOGLE"
                        label="Google Search Vol, global"
                        onChange={this.changeHandler}
                        labelLower=""
                    />
                    <GoogleCPC
                        id="CPC"
                        label="Google CPC, global"
                        onChange={this.changeHandler}
                        labelLower="$"
                    />
                    <ParkingViews
                        id="VIEWS"
                        label="Parking Views, last 31d"
                        onChange={this.changeHandler}
                        labelLower=""
                    />
                    <DomainAge
                        id="DOMAIN_AGE"
                        label="Domain Age"
                        onChange={this.changeHandler}
                        labelLower=""
                    />
                </section>
                <section label="Majestic">
                    <MajesticBacklinks
                        id="MAJESTIC"
                        label="Majestic Backlinks"
                        onChange={this.changeHandler}
                        labelLower="Backlinks"
                    />
                    <MajesticDomainPop
                        id="DOMAINPOP"
                        label="Majestic Domain Pop"
                        onChange={this.changeHandler}
                        labelLower=""
                    />
                </section>
            </Tabs>
        )
    };

    renderBasicFilters = () => {
        return (
            <Tabs>
                <section label="General">
                    <Keyword
                        id="KEYWORD"
                        label="Keyword"
                        onChange={this.changeHandler}
                    />
                    <DomainLength
                        id="LENGTH"
                        label="Domain Length"
                        onChange={this.changeHandler}
                        labelLower="Characters"
                    />
                    <Tlds
                        id="TLDS"
                        label="TLD Selection"
                        tlds={this.props.filters.TLDS}
                        onChange={this.changeHandler}
                    />
                    <MajesticBacklinks
                        id="MAJESTIC"
                        label="Majestic Backlinks"
                        onChange={this.changeHandler}
                        labelLower="Backlinks"
                    />
                    <GoogleSearchVolume
                        id="GOOGLE"
                        label="Google Search Vol, global"
                        onChange={this.changeHandler}
                        labelLower=""
                    />
                </section>
            </Tabs>
        )
    };

    render() {
        let filterCount = Object.keys(this.props.filters).length;

        return (
            <div className={'filters wrapper filters--' + this.props.mode }>
                { this.props.mode === 'basic' && this.renderBasicFilters() }
                { this.props.mode === 'expert' && this.renderExpertFilters() }
                { filterCount > 2 && this.renderResetButton() }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        filters: state.filters,
        sorting: state.sorting,
        mode: state.mode,
    };
};

export default connect(mapStateToProps, {filterChanged, fetchDomains})(Filters);
