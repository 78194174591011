import React from 'react';

class Label extends React.Component {

    render() {

        let labelFor = this.props.for ? this.props.for : '';

        return (
            <label className={'label'} htmlFor={labelFor.toString()}>{this.props.text}</label>
        );
    };

}

export default Label;
