export default (state = [], action) => {
    switch (action.type) {
        case 'FETCH_DOMAINS':
            return action.payload;
        case 'NEXT_DOMAINS':
            return [...state, ...action.payload];
        default:
            return state;
    }
}
