export const FILTERS = {
    KEYWORD: 'domainIdn.contains',
    TLD: 'tldIdn.equals',
    TLDS: 'tldIdn.in',
    IDN: 'isIdn.equals',
    HYPHENS: 'hyphensCount.equals',
    DIGITS: 'digitsCount.equals',
    GOOGLE_FROM: 'searchVolume.greaterOrEqualThan',
    GOOGLE_TO: 'searchVolume.lessOrEqualThan',
    LENGTH_FROM: 'domainLength.greaterOrEqualThan',
    LENGTH_TO: 'domainLength.lessOrEqualThan',
    MAJESTIC_FROM: 'externalBacklinksCount.greaterOrEqualThan',
    MAJESTIC_TO: 'externalBacklinksCount.lessOrEqualThan',
    DOMAINPOP_FROM: 'referringDomainsCount.greaterOrEqualThan',
    DOMAINPOP_TO: 'referringDomainsCount.lessOrEqualThan',
    CPC_FROM: 'cpc.greaterOrEqualThan',
    CPC_TO: 'cpc.lessOrEqualThan',
    SEARCHVOLUME_FROM: 'searchVolume.greaterOrEqualThan',
    SEARCHVOLUME_TO: 'searchVolume.lessOrEqualThan',
    TIME_FROM: 'auctionEndDate.greaterOrEqualThan',
    TIME_TO: 'auctionEndDate.lessOrEqualThan',
    VIEWS_FROM: 'views.greaterOrEqualThan',
    VIEWS_TO: 'views.lessOrEqualThan',
    DOMAIN_AGE_FROM: 'registrationDate.lessOrEqualThan',
    DOMAIN_AGE_TO: 'registrationDate.greaterOrEqualThan'
};

export const SORTING = {
    TIME: 'auctionEndDate',
    PRICE: 'auctionCurrentBid',
    VOLUME: 'searchVolume',
    BACKLINKS: 'externalBacklinksCount',
    DOMAIN: 'domainIdn',
    REFERRING: 'referringDomainsCount',
    CPC: 'cpc',
    BIDS: 'auctionBidsCount',
    VIEWS: 'views',
    DOMAINAGE: 'registrationDate'
};

export const FILTER_VALUE_MODIFIERS = {
    DOMAIN_AGE_FROM: function(v) {
            let d = new Date();
            return new Date(Date.UTC(d.getUTCFullYear() - v, d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes())).toISOString();
        },
    DOMAIN_AGE_TO: function(v) {
        let d = new Date();
        return new Date(Date.UTC(d.getUTCFullYear() - v - 1, d.getUTCMonth(), 1)).toISOString();
    }
};
