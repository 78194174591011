import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import connect from "react-redux/es/connect/connect";
import {changeSort, fetchDomains} from "../../../actions/index";

require('../../../stylesheets/components/filters/_Sorter.scss');

class Sorter extends React.Component {

    constructor() {
        super();
        this.toggleSorting = this.toggleSorting.bind(this);
    }

    toggleSorting = () => {
        this.props.changeSort(this.props.id);
    };

    componentDidUpdate(prevProps) {
        if(
            prevProps.id === this.props.sorting.sortBy &&
            (
                prevProps.sorting.sortBy !== this.props.sorting.sortBy ||
                prevProps.sorting.sortDirection !== this.props.sorting.sortDirection
            )
        ){
            this.buildSortingPostObject(this.props.filters, this.props.sorting);
        }
    };

    buildSortingPostObject = (filters, sorting) => {
        if (filters.page !== 0) {
            filters.page = 0;
        }
        this.props.fetchDomains(filters, sorting);
    };

    render() {
        let specialClass = '';
        let side = 'left';

        if(this.props.sorting.sortBy === this.props.id){
            specialClass += ' sorter--active--' + this.props.sorting.sortDirection;
        }
        if(this.props.side){
            side = this.props.side;
        }

        specialClass += ' sorter--' + side;

        return (
            <button onClick={this.toggleSorting} className={'sorter' + specialClass}>
                <span className={'sorter__indicators'}>
                    <FontAwesomeIcon icon={['fal','sort']} className={'sorter__indicator'} fixedWidth />
                    <FontAwesomeIcon icon={['fas','sort-up']} className={'sorter__indicator sorter__indicator--asc'} fixedWidth />
                    <FontAwesomeIcon icon={['fas','sort-down']} className={'sorter__indicator sorter__indicator--desc'} fixedWidth />
                </span>
                <span className={'sorter__label'}>{this.props.label}</span>
            </button>
        );
    };

}

const mapStateToProps = (state) => {
    return {
        filters: state.filters,
        options: state.options,
        sorting: state.sorting
    };
};

export default connect(mapStateToProps, {changeSort, fetchDomains})(Sorter);
