import React from 'react';
import Button from "../../filters/presentation/Button";
import smoothscroll from 'smoothscroll-polyfill';
import ReactGA from 'react-ga';

require('../../../stylesheets/components/layout/_GoToTop.scss');

smoothscroll.polyfill();

class GoToTop extends React.Component {

    gototop = () => {
        ReactGA.event({
            category: 'Results',
            action: 'To Top',
        });
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    };

    listenToScroll = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

        this.setState({
            pos: winScroll,
        })
    };

    constructor() {
        super();
        this.state = {
            pos: document.body.scrollTop || document.documentElement.scrollTop
        };
    };

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    };

    render() {
        let className = '';
        if(this.state.pos < 400) {
            className = ' gotop--hidden';
        }
        return (
            <div className={'gotop' + className}>
                <Button
                    type={'switch'}
                    classes={'btn--blue btn--smaller btn--gototop'}
                    label={''}
                    iconStart={['fas','arrow-alt-up']}
                    iconEnd
                    onClick={this.gototop}
                />
            </div>
        )
    }
}
export default GoToTop;
