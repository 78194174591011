import 'rc-slider/assets/index.css';
import React from 'react';
import Slider from 'rc-slider';
import connect from "react-redux/es/connect/connect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
const Range = Slider.Range;

require('../../../stylesheets/components/filters/_RangeSlider.scss');

class RangeSlider extends React.Component {

    componentDidMount() {
        let min = this.props.default[0];
        let max = this.props.default[1];
        let isChanged = false;
        if(typeof this.props.setFilters[this.props.id + '_FROM'] !== 'undefined'){
            isChanged = true;
            min = this.props.setFilters[this.props.id + '_FROM'];
            if(typeof this.props.setFilters[this.props.id + '_TO'] !== 'undefined'){
                max = this.props.setFilters[this.props.id + '_TO'];
            }
        }
        this.setState({
            value: [parseInt(min), parseInt(max)],
            isChanged: isChanged
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            value: [props.default[0],props.default[1]],
            isChanged: false
        };

        this.changeHandler = this.changeHandler.bind(this);
    }

    componentDidUpdate(){
        if(this.state.isChanged === true){
            let min = this.props.setFilters[this.props.id + '_FROM'];
            if(typeof min === 'undefined'){
                this.setState({
                    value: [this.props.default[0], this.props.default[1]],
                    isChanged: false
                });
            }
        }
    };

    changeHandler = values => {
        if(values[0] !== this.props.default[0] || values[1] !== this.props.default[1]){
            this.setState({isChanged: true});
        }

        this.setState({
            value: [values[0],values[1]]
        });
        if(values[1] === this.props.max){
            this.props.onChange({[this.props.id]: [values[0],'']});
        }else{
            this.props.onChange({[this.props.id]: [values[0],values[1]]});
        }
    };

    render() {

        let highestVal = this.state.value[1];
        if(highestVal === this.props.max){
            highestVal = <FontAwesomeIcon icon={['far','infinity']} fixedWidth />;
        }

        return (
            <div className={'input'}>
                <div className={'input--range'}>
                    <Range
                        step={this.props.steps}
                        pushable={true}
                        allowCross={false}
                        value={this.state.value}
                        onChange={this.changeHandler}
                        max={this.props.max}
                        min={this.props.min}
                    />
                    <div className={'input--range__hint'}>
                        From <span>{this.state.value[0]}</span> to <span>{highestVal}</span> {this.props.labelLower}
                    </div>
                </div>
            </div>
        );
    };

}

const mapStateToProps = (state) => {
    return {setFilters: state.filters};
};

export default connect(mapStateToProps)(RangeSlider);
