import expiredDomains from '../apis/expiredDomains';
import {FILTER_VALUE_MODIFIERS, FILTERS, SORTING} from '../components/filters/Mapping';

const defaultFilters = {
    size: 30,
    page: 0
};

export const changeSort = (sorting) => {
    return {
        type: 'SORT_CHANGED',
        payload: sorting
    }
};

export const changeMode = (mode) => {
    return {
        type: 'MODE_CHANGED',
        payload: mode
    };
};

export const invertSortDirection = (sortDirection) => {
    return sortDirection === 'asc' ? 'desc' : 'asc';
};

export const fetchDomains = (filters, sorting) => async (dispatch) => {
    let requestParams = {...filters, sort: sorting.sortBy + ',' + sorting.sortDirection};
    let queryParams = {...requestParams};

    delete queryParams.page;
    delete queryParams.size;

    // console.log('request', requestParams);
    // console.log('queryParams', queryParams);

    //Process request parameters
    for (let param in requestParams) {
        if (FILTERS[param]) { //Remap parameter
            requestParams[FILTERS[param]] = requestParams[param];
            if(FILTER_VALUE_MODIFIERS[param]) {
                requestParams[FILTERS[param]] = FILTER_VALUE_MODIFIERS[param](requestParams[param])
            }
            delete requestParams[param];
        } else if (param === 'sort') {
            let sortingOptions = queryParams[param].split(',');
            let sortDirection = sortingOptions[1];
            if(sortingOptions[0].toUpperCase() === 'DOMAINAGE') {
                sortDirection = invertSortDirection(sortDirection);
            }
            requestParams['sort'] = SORTING[sortingOptions[0].toUpperCase()] + ',' + sortDirection;
        }
    }

    //Process URL parameters
    let queryParamsString = '';
    for (let param in queryParams) {
        if (param) {
            queryParamsString += param.toLowerCase() + '=' + queryParams[param] + '&';
        }
    }
    queryParamsString = queryParamsString.slice(0, -1);


    if (queryParamsString !== '') {
        window.history.pushState({}, null, '/?' + queryParamsString);
    }

    const response = await expiredDomains.get('/search', {params: requestParams});

    let type = 'FETCH_DOMAINS';
    if (filters.page !== 0) {
        type = 'NEXT_DOMAINS';
    }

    dispatch({
        type: type,
        payload: response.data,
        filters: filters
    });
};

export const filterChanged = (filter) => {
    if(filter.hasOwnProperty('action')) {
        if(filter.action === 'reset') {
            return {
                type: 'FILTER_RESET',
                payload: defaultFilters
            };
        }
    }

    if (filter.value === "" || filter.value === undefined) {
        return {
            type: 'FILTER_DELETED',
            payload: {
                'id': filter.id
            }
        };
    } else {
        return {
            type: 'FILTER_CHANGED',
            payload: {
                'id': filter.id,
                'value': filter.value
            }
        };
    }
};

export const initialSetup = () => {
    return {
        type: 'FILTER_CHANGED',
        payload: defaultFilters
    };
};
