import React from 'react';
import Countdown from 'react-countdown-now';
import { calcTimeDelta } from 'react-countdown-now';
// import Button from "../filters/presentation/Button";
import Modal from "../layout/Modal";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import connect from "react-redux/es/connect/connect";
import TrackVisibility from 'react-on-screen';
import ReactGA from 'react-ga';
require('../../stylesheets/components/results/_ListItem.scss');

const Completionist = () => <span>finished</span>;
const StaticTime = ({domain}) => {
    let timeDelta = calcTimeDelta(domain, Date.now());
    return renderCountdown(timeDelta);
};

// Renderer callback with condition
const renderCountdown = ({ total, days, hours, minutes, seconds, milliseconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <Completionist/>;
    } else {
        hours = hours >= 10 ? hours : '0'+hours;
        minutes = minutes >= 10 ? minutes : '0'+minutes;
        seconds = seconds >= 10 ? seconds : '0'+seconds;

        if (days > 0) {
            return <span>{days}d {hours}:{minutes}:{seconds}</span>;
        } else {
            return <span>{hours}:{minutes}:{seconds}</span>;
        }

    }
};

class ListItem extends React.Component {

    constructor() {
        super();

        this.loadModal = this.loadModal.bind(this);
        this.trackBid = this.trackBid.bind(this);
    }

    addToWatchlist = () => {
        // console.log(this.props.domain);
    };

    loadModal = () => {
        Modal.show({
            title: this.props.domain.domainIdn,
            closeOnClick: false,
            content: this.renderModalContent(this.props.domain)
        });
    };

    renderModalContent = (domain) => {
        return (
            <div className={'odp'}>
                <ul>
                    <li>auctionCurrency: {domain.auctionCurrency}</li>
                    <li>auctionDuration: {domain.auctionDuration}</li>
                    <li>auctionEndDate: {domain.auctionEndDate}</li>
                    <li>auctionReservePrice: {domain.auctionReservePrice}</li>
                    <li>auctionStartDate: {domain.auctionStartDate}</li>
                    <li>auctionStartPrice: {domain.auctionStartPrice}</li>
                    <li>auctionStatus: {domain.auctionStatus}</li>
                    <li>bidsCount: {domain.bidsCount}</li>
                    <li>digitsCount: {domain.digitsCount}</li>
                    <li>domainAce: {domain.domainAce}</li>
                    <li>domainIdn: {domain.domainIdn}</li>
                    <li>domainLength: {domain.domainLength}</li>
                    <li>externalBacklinksCount: {domain.externalBacklinksCount}</li>
                    <li>highestBid: {domain.highestBid}</li>
                    <li>hyphensCount: {domain.hyphensCount}</li>
                    <li>id: {domain.id}</li>
                    <li>isIdn: {domain.isIdn}</li>
                    <li>latestBid: {domain.latestBid}</li>
                    <li>referringDomainsCount: {domain.referringDomainsCount}</li>
                    <li>registeredTldList: {domain.registeredTldList}</li>
                    <li>registeredTldListContentType: {domain.registeredTldListContentType}</li>
                    <li>registeredTldsCount: {domain.registeredTldsCount}</li>
                    <li>domainAge: {domain.registrationDate}</li>
                    <li>tldAce: {domain.tldAce}</li>
                    <li>tldIdn: {domain.tldIdn}</li>
                </ul>
            </div>
        );
    };

    renderNumber = (number, thousandsSep = ',') => {
        if(typeof number === 'number'){
            return String(number).replace(/(.)(?=(\d{3})+$)/g,'$1'+thousandsSep);
        }
    };

    renderAge = (regDate) => {
        regDate = new Date(regDate);
        let years =  new Number((new Date().getTime() - regDate.getTime()) / 31536000000).toFixed(0);
        switch (true) {
            case (years < 1): return "<1 year";
            case (years == 1): return "1 year";
            case (years > 1): return years + " years";
        }
    };

    renderTableColumns = () => {
        let currencies = {
            USD: '$',
            EUR: '€',
            GBP: '£'
        };

        return this.props.options.columns[this.props.mode].map((column) => {

            let className = 'domain__data domain__data--' + column.id;
            if (column.hasOwnProperty('style')) {
                className += ' domain__data--' + column.style;
            }
            if (column.hasOwnProperty('align')) {
                className += '  domain__data--align_' + column.align;
            }

            if (!this.props.domain.hasOwnProperty(column.id)) {
                return (
                    <div key={column.id} className={className}>
                        <abbr title="This data is not yet available">-</abbr>
                    </div>
                );
            }

            if (column.id === 'cpc') {
                return (
                    <div key={column.id} className={className}>
                        {this.renderNumber(this.props.domain[column.id])} {this.props.domain['cpcCurrency']}
                    </div>
                )
            }

            if (column.id === 'auctionCurrentBid') {
                if (!this.props.domain[column.id]) {
                    return (
                        <div key={column.id} className={className}>
                            <abbr title="This data is not yet available">&mdash;</abbr>
                        </div>
                    )
                }else{
                    return (
                        <div key={column.id} className={className}>
                            {this.renderNumber(this.props.domain[column.id])} {currencies[this.props.domain['auctionCurrency']]}
                        </div>
                    )
                }
            }

            if (typeof this.props.domain[column.id] === 'number') {
                return (
                    <div key={column.id} className={className}>
                        {this.renderNumber(this.props.domain[column.id])} {column.id === 'auctionReservePrice' && this.props.domain.auctionCurrency}
                    </div>
                )
            }

            if (column.id === 'auctionEndDate') {
                return (
                    <div key={column.id} className={className}>
                        <TrackVisibility
                            partialVisibility
                            offset={100}
                        >
                            {({ isVisible }) => isVisible ? <Countdown
                                date={this.props.domain[column.id]}
                                renderer={renderCountdown}
                                intervalDelay={1000}
                            />: <StaticTime domain={this.props.domain[column.id]} />}
                        </TrackVisibility>
                    </div>
                )
            }

            if (
                this.props.domain[column.id] === null ||
                this.props.domain[column.id] === ''
            ) {
                return (
                    <div key={column.id} className={className}>
                        <abbr title="This data is not yet available">&mdash;</abbr>
                    </div>
                )
            }

            if (column.id === 'registrationDate') {
                return (
                    <div key={column.id} className={className}>
                        {this.renderAge(new Date(this.props.domain[column.id]).toISOString().slice(0, 10))} {currencies[this.props.domain['registrationDate']]}
                    </div>
                )
            }

            return (
                <div key={column.id} className={className}>
                    {this.props.domain[column.id]}
                </div>
            );

        });
    };

    trackBid = () => {
        ReactGA.event({
            category: 'Results',
            action: 'Bid Now',
            label: this.props.domain.domainIdn
        });
    };

    render() {
        return (
            <div className="domain">
                {this.renderTableColumns()}
                <div className="domain__data domain__data--button">
                    <a onClick={this.trackBid} target={'_blank'} href={ process.env.REACT_APP_SEDO_URL + 'search/details/?domain=' + this.props.domain.domainAce + '&origin=edstandalone&language=e'} className="btn btn--green btn--bid">
                        <span>Bid now</span>
                    </a>
                </div>
            </div>
        );
        /**
         * Watchlist-Button
         <div className={'addToWatchlist'} title={this.props.domain.domainIdn}>
         <button className={'btn btn--transparent'} onClick={this.addToWatchlist}>
         <FontAwesomeIcon icon={['fal','heart']} />
         </button>
         </div>
         * Modal
         <Button
         type={'switch'}
         classes={'btn--green btn--bid'}
         label={<span>Bid now</span>}
         iconStart
         iconEnd
         onClick={this.loadModal}
         />
         */
    }
}

const mapStateToProps = (state) => {
    return {
        mode: state.mode,
        options: state.options
    };
};

export default connect(mapStateToProps)(ListItem);
