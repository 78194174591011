import React from 'react';
import connect from "react-redux/es/connect/connect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class InputRadio extends React.Component {
    componentDidMount() {
        if(typeof this.props.setFilters[this.props.id] !== 'undefined'){
            this.setState({value:this.props.setFilters[this.props.id]});
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value ? this.props.value : ''
        };

        this.changeHandler = this.changeHandler.bind(this);
    }

    changeHandler = event => {
        this.setState({value: event.target.value});
        this.props.onChange(event); //call onChange event in parent
    }

    render() {

        return (
            <div className={'input'}>
                <label className={'input--radio'}>
                    <input type="radio" name={this.props.id} id={this.props.id} value={this.state.value} onChange={this.changeHandler}/>
                    <i>
                        <FontAwesomeIcon icon={['fal','times']}/>
                    </i>
                    <span className={'input--radio__description'}>{this.props.label}</span>
                </label>
            </div>
        );
    };

}

const mapStateToProps = (state) => {
    return {setFilters: state.filters};
};

export default connect(mapStateToProps)(InputRadio);
