import React from 'react';
import connect from "react-redux/es/connect/connect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

require('../../../stylesheets/components/filters/_InputText.scss');

class InputText extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: (this.props.value !== undefined) ? this.props.value : '',
            isChanged: false
        };

        this.changeHandler = this.changeHandler.bind(this);
    };

    componentDidMount() {
        if(typeof this.props.setFilters[this.props.id] !== 'undefined'){
            this.setState({value:this.props.setFilters[this.props.id]});
        }
    };

    componentDidUpdate() {
        if(this.props.resetable && this.state.isChanged === true){
            if(!this.props.setFilters.hasOwnProperty(this.props.id)){
                this.setState({
                    value: '',
                    isChanged: false
                });
            }
        }
    };

    changeHandler = event => {
        if(event.target.value !== ''){
            this.setState({isChanged: true});
        }
        this.setState({value: event.target.value});
        this.props.onChange(event); //call onChange event in parent
    };

    render() {
        let hasIcon = this.props.hasOwnProperty('icon');
        let placeholder = (this.props.placeholder !== undefined) ? this.props.placeholder : '';
        if(hasIcon === true && Array.isArray(this.props.icon)){
            return (
                <div className={'input'}>
                    <label className={'input--text input--text--hasIcon'}>
                        <input type="text" placeholder={placeholder.toString()} className={'input--text__field'} id={this.props.id} value={this.state.value} onChange={this.changeHandler}/>
                        <FontAwesomeIcon icon={this.props.icon} className={'input--text__icon'} />
                    </label>
                </div>
            );
        }

        return (
            <div className="input">
                <input type="text" placeholder={placeholder.toString()} className={'input--text input--text__field'} id={this.props.id} value={this.state.value} onChange={this.changeHandler}/>
            </div>
        );
    };

}

const mapStateToProps = (state) => {
    return {setFilters: state.filters};
};

export default connect(mapStateToProps)(InputText);
