import React from 'react';
import connect from "react-redux/es/connect/connect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactGA from 'react-ga';

require('../../../stylesheets/components/filters/_Button.scss');

class Button extends React.Component {

    constructor(props) {
        super(props);

        this.clickHandler = this.clickHandler.bind(this);
    }

    clickHandler = event => {
        if (this.props.tracking) {
            this.tracking(this.props.tracking);
        }

        if (this.props.onClick) {
            this.props.onClick(event);
        }
    };

    tracking = (data) => {
        ReactGA.event(data);
    };

    renderIcon(location) {

        let icon;
        let className;

        if(location === 'start'){
            icon = this.props.iconStart;
            className = 'btn__icon--start';
        }
        if(location === 'end'){
            icon = this.props.iconEnd;
            className = 'btn__icon--end';
        }

        if(Array.isArray(icon)){
              return <FontAwesomeIcon icon={icon} className={className} />;
        }else if(typeof icon === 'string'){
            return <FontAwesomeIcon icon={icon} className={className} />; // uses fas prefix
        }

        return false;
    }


    render() {
        const isUrl = this.props.type === 'url' ? true : false;

        if(isUrl === true){
            const target = this.props.target !== '' ? this.props.target : '';
            return (
                <a onClick={this.clickHandler} href={this.props.url} target={target} className={'btn '+this.props.classes}>
                    {this.renderIcon('start')}
                    <span className={'btn__label'}>{this.props.label}</span>
                    {this.renderIcon('end')}
                </a>
            );
        }

        return (
            <button onClick={this.clickHandler} className={'btn '+this.props.classes}>
                {this.renderIcon('start')}
                {this.props.label}
                {this.renderIcon('end')}
            </button>
        );
    };

}

const mapStateToProps = (state) => {
    return {mode: state.mode};
};

export default connect(mapStateToProps)(Button);
