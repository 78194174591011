import React from 'react';
import BaseFilter from './BaseFilter';
import Dropdown from './Dropdown';
import _ from 'lodash';

class Tlds extends BaseFilter {

    constructor() {
        super();

        this.state = {
            checkedTlds: [],
            tldList: [
                {id: 'com', label: '.com', type: 'checkbox'},
                {id: 'net', label: '.net', type: 'checkbox'},
                {id: 'org', label: '.org', type: 'checkbox'},
                {id: 'biz', label: '.biz', type: 'checkbox'},
                {id: 'cc', label: '.cc', type: 'checkbox'},
                {id: 'io', label: '.io', type: 'checkbox'},
                {id: 'info', label: '.info', type: 'checkbox'},
                {id: 'online', label: '.online', type: 'checkbox'},
                {id: 'club', label: '.club', type: 'checkbox'},
                {id: 'global', label: '.global', type: 'checkbox'},
            ]
        };
    }

    componentDidMount() {
        if(typeof this.props.tlds !== 'undefined'){

            let splitTlds = this.props.tlds.split(',');

            if (splitTlds.length > 0) {
                let newTldList = this.state.tldList.map((tld) => {
                    if (_.indexOf(splitTlds, tld.id) >= 0) {
                        tld.checked = true;
                    }
                    return tld;
                });

                this.setState({
                    checkedTlds: splitTlds,
                    tldList: newTldList
                });
            }

        }
    };

    componentDidUpdate(prevProps, prevState) {
        let newTldList = [];
        if (prevState.checkedTlds.length !== this.state.checkedTlds.length) {
            if (typeof this.props.tlds === 'undefined') {

                newTldList = this.state.tldList.map((tld) => {
                    tld.checked = false;
                    return tld;
                });

            } else {

                let splitTlds = this.props.tlds.split(',');
                newTldList = this.state.tldList.map((tld) => {
                    if (_.indexOf(splitTlds, tld.id) >= 0) {
                        tld.checked = true;
                    } else {
                        tld.checked = false;
                    }
                    return tld;
                });

            }

            this.setState({tldList: newTldList});

        }
    };

    renderRestrictions() {
        if(this.state.checkedTlds){
            return (
                <span className={'filter__item__textList'}>{this.state.checkedTlds.join(', ')}</span>
            );
        }
    };

    renderNoRestrictions = () => {
        return (
            <span className={'filter__item__textList'}>All TLDs shown</span>
        )
    };

    changeHandler = event => {
        event.target.id = 'TLDS';
        let newTldStore = this.props.tlds;

        if (event.target.checked) { //need to add to store
            if (this.props.tlds) { // is not empty, add value to store
                newTldStore += ',' + event.target.value;
                this.setState({checkedTlds: [...this.state.checkedTlds, event.target.value]});
            } else { // is empty, initial tlds store
                newTldStore = event.target.value;
                this.setState({checkedTlds: [event.target.value]});
            }
        } else { //remove from store
            let regex = new RegExp("(,)?" + event.target.value + "\\b");
            newTldStore = newTldStore.replace(regex, '');
            this.setState({checkedTlds: this.state.checkedTlds.filter(tld => tld !== event.target.value)});

            if (newTldStore[0] === ',') {
                newTldStore = newTldStore.substr(1);
            }

            event.target.checked = newTldStore.length > 1;
        }
        event.target.value = newTldStore;
        this.props.onChange(event); //call onChange event in parent
    };

    render() {
        const tlds = [
            {
                key: 'standard',
                items: this.state.tldList
            }
        ];

        return (
            <div className={'filter__item filter__item--tlds'}>
                <Dropdown
                    options={tlds}
                    buttonClasses={'btn--transparent btn--smaller btn--dropdown btn--label btn--tldsFilter'}
                    dropdownClass={'doubleColumns'}
                    isComingSoon={false}
                    buttonLabel={this.props.label}
                    isDisplayDropdown={true}
                    dropdownStyle={'right'}
                    onChange={this.changeHandler}
                />
                {this.props.tlds ? this.renderRestrictions() : this.renderNoRestrictions()}

            </div>
        );

    }
}

export default Tlds;
