export default (state = [], action) => {
    if (action.type === 'FILTER_CHANGED') {
        if(state.page !== 0 && action.payload.id !== 'page'){
            return {...state, [action.payload.id]: action.payload.value, page: 0};
        }

        return {...state, [action.payload.id]: action.payload.value};
    }
    if (action.type === 'FILTER_DELETED') {
        delete  state[action.payload.id];
        return {...state};
    }
    if (action.type === 'FILTER_RESET') {
        return action.payload;
    }

    return state;
}
