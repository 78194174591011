import React from 'react';
import connect from "react-redux/es/connect/connect";
import Sorter from "../filters/presentation/Sorter";

require('../../stylesheets/components/results/_DomainListHeader.scss');

class DomainListHeader extends React.Component {

    renderResultCount() {
        return (
            <p className="domain_header_resultCount">
                {this.props.results} / { this.props.resultsCountFiltered } Results
            </p>
        )
    }

    renderTableHeader() {
        return this.props.options.columns[this.props.mode].map((column) => {
            if (column.show === true) {
                let className = 'default';
                let sorterSide = 'right';
                if (column.hasOwnProperty('style')) {
                    className = column.style;
                }

                if (column.hasOwnProperty('align')) {
                    className += ' domain_header_column--' + column.align;
                }

                if (column.id === 'domainIdn') {
                    sorterSide = 'right';
                }


                    return (
                    <div key={column.id} className={'domain_header_column domain_header_column--' + column.id + ' domain_header_column--' + className}>
                        <Sorter
                            label={column.label}
                            id={column.mapping ? column.mapping : ''}
                            side={sorterSide}
                        />
                    </div>
                );
            }else{
                return false;
            }

        });
    }

    render() {
        return (
            <div className={'domain_header'}>
                { this.renderTableHeader() }
                <div className={'domain_header_column domain_header_column-empty domain_header_column--button'} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        results: state.domains.length,
        resultsCountFiltered: 47.132,
        mode: state.mode,
        options: state.options
    };
};

export default connect(mapStateToProps)(DomainListHeader);
