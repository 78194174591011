import React from 'react';
import Button from '../filters/presentation/Button';
import Dropdown from "../filters/Dropdown";
import Modal from "./Modal";
import connect from "react-redux/es/connect/connect";
import { changeMode } from "../../actions/index";
import ReactGA from 'react-ga';

require('../../stylesheets/components/bar/_Bar.scss');

class Bar extends React.Component {

    constructor() {
        super();

        this.comingSoon = this.comingSoon.bind(this);
    }

    comingSoon = () => {
        Modal.show({
            title: 'Coming Soon',
            closeOnClick: true,
            content: <p>We're sorry, but this feature is not yet available.<br />Give us a bit more time to get everything working.</p>
        });
    };

    renderResultCount = () => {
        return (
            <p className="domains__resultCount">
                {this.props.results} Results
            </p>
        );
    };

    renderExpertMode = () => {

        let sortingOptions = this.props.options.sorting[this.props.mode];

        return (
            <div className={'bar--left'}>
                <Button
                    type={'switch'}
                    classes={'btn--black btn--smaller btn--mode'}
                    label={'Activate Basic Mode'}
                    iconStart={['fa','user']}
                    iconEnd
                    onClick={this.toggleMode}
                />

                <Dropdown
                    options={sortingOptions}
                    buttonClasses={'btn--blue btn--smaller btn--dropdown btn--sorting'}
                    dropdownClass={'sorting'}
                    isComingSoon={false}
                    buttonLabel={'Sort Options'}
                    buttonIconStart={['fal','sort-amount-down']}
                    dropdownStyle={'right'}
                />
            </div>
        )
    };

    renderBasicMode = () => {
        let sortingOptions = this.props.options.sorting[this.props.mode];

        return (
            <div className={'bar--left'}>
                <Button
                    type={'switch'}
                    classes={'btn--black btn--smaller btn--mode'}
                    label={'Activate Expert Mode'}
                    iconStart={['fal','user-astronaut']}
                    iconEnd
                    onClick={this.toggleMode}
                />

                <Dropdown
                    options={sortingOptions}
                    buttonClasses={'btn--blue btn--smaller btn--dropdown btn--sorting'}
                    dropdownClass={'sorting'}
                    buttonLabel={'Sort Options'}
                    isComingSoon={false}
                    buttonIconStart={['fal','sort-amount-down']}
                    dropdownStyle={'right'}
                />
            </div>
        )
    };

    toggleMode = () => {
        let newMode = 'basic';
        if(this.props.mode === 'basic'){
            newMode = 'expert';
        }

        ReactGA.event({
            category: 'Results',
            action: 'Modus Changed',
            label: newMode
        });

        this.props.changeMode(newMode);
    };

    render() {
        return (
            <div className={'bar wrapper'}>
                { this.props.mode === 'basic' && this.renderBasicMode() }
                { this.props.mode === 'expert' && this.renderExpertMode() }
                <div className={'bar--right'}>
                    <Button
                        type={'url'}
                        classes={'btn--blue btn--smaller'}
                        label={'Export all Auctions'}
                        iconStart={['fal','file-export']}
                        iconEnd
                        url={'/api/search/export'}
                        target={'_blank'}
                        tracking={{
                            category: 'Results',
                            action: 'Export Results'
                        }}
                    />
                </div>
            </div>
        );
        /**
         * Resultcount
         { this.props.results > 0 && this.renderResultCount() }
         * Search
         <Button
         type={'switch'}
         classes={'btn--black btn--smaller'}
         label={'My Watchlist'}
         iconStart={['fal','heart']}
         iconEnd
         onClick={this.comingSoon}
         />
         * Dropdown for the export - just in case
         * <Dropdown
         options={this.props.options.export}
         buttonClasses={'btn--blue btn--smaller'}
         buttonLabel={'Export Results'}
         isComingSoon={true}
         buttonIconStart={['fal','file-export']}
         />

         * Dropdown Display Options
         <Dropdown
         options={displayOptions}
         buttonClasses={'btn--blue btn--smaller btn--dropdown btn--display'}
         dropdownClass={'display'}
         isComingSoon={false}
         buttonLabel={'Display Options'}
         buttonIconStart={['fal','filter']}
         isDisplayDropdown={true}
         dropdownStyle={'right'}
         />

         **/
    }
}

const mapStateToProps = (state) => {
    return {
        results: state.domains.length,
        resultsCountFiltered: 47.132,
        mode: state.mode,
        options: state.options
    };
};

export default connect(mapStateToProps, {changeMode})(Bar);
