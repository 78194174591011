import React from 'react';
import BaseFilter from './BaseFilter';
import RangeSlider from './presentation/RangeSlider';
import Label from "./presentation/Label";

class MajesticDomainPop extends BaseFilter {

    render() {

        return (
            <div className={'filter__item filter__item--range'}>
                <div className={'filter__item__wrapper'}>
                    <Label
                        for={this.props.id}
                        text={this.props.label}
                    />
                    <RangeSlider
                        id={this.props.id}s
                        onChange={this.changeHandler}
                        value={this.props.value ? this.props.value : ''}
                        label={this.props.label}
                        labelLower={this.props.labelLower}
                        min={0}
                        max={300}
                        steps={5}
                        default={[0,300]}
                    />
                </div>
            </div>
        );
    }
}

export default MajesticDomainPop;
