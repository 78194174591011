import React from 'react';
import connect from "react-redux/es/connect/connect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

require('../../../stylesheets/components/filters/_Checkbox.scss');

class InputCheckbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value ? this.props.value : '',
            isChecked: this.props.checked ? true : false,
            checked: this.props.checked ? this.props.checked : false
        };

        this.changeHandler = this.changeHandler.bind(this);
    };

    componentDidMount() {
        if(typeof this.props.setFilters[this.props.id] !== 'undefined'){
            this.setState({isChecked: true});
        }
    };

    componentDidUpdate() {
        if(this.props.resetable && this.state.isChecked === true){
            if(!this.props.setFilters.hasOwnProperty(this.props.id)){
                this.setState({
                    checked: false,
                    isChecked: false
                });
            }
        }
    };

    changeHandler = event => {
        let checked = false;
        if(event.target.checked === true){
            checked = true;
        }
        this.setState({
            value: event.target.value,
            isChecked: checked
        });
        this.props.onChange(event); //call onChange event in parent
    };

    render() {
        let checked = false;
        if(this.state.isChecked){
            checked = true;
        }
        let onChangeHandler = this.changeHandler;
        if (this.props.action) {
            onChangeHandler = this.props.changeHandler;
        }
        return (
            <div className={'input'}>
                <label className={'input--checkbox'}>
                    <input type="checkbox" id={this.props.id} checked={checked} value={this.state.value} onChange={onChangeHandler} />
                    <i>
                        <FontAwesomeIcon icon={['fal','times']}/>
                    </i>
                    <span className={'input--checkbox__description'}>{this.props.label}</span>
                </label>
            </div>
        );
    };

}

const mapStateToProps = (state) => {
    return {setFilters: state.filters};
};

export default connect(mapStateToProps)(InputCheckbox);
