import React from 'react';
import { connect } from 'react-redux';
import {fetchDomains, initialSetup, filterChanged, changeMode} from '../../actions/index';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DomainListItem from './ListItem';
import DomainListHeader from './DomainListHeader';
import Button from "../filters/presentation/Button";
import ReactGA from 'react-ga';

require('../../stylesheets/components/results/_List.scss');

class DomainList extends React.Component {
    constructor() {
        super();

        this.state = {
            isInitial: true,
            isMobileExpert: false
        };

        this.loadMore = this.loadMore.bind(this);

        window.onresize = () => {
            if (this.props.mode === 'expert' && window.outerWidth <= 799) {
                this.setState({isMobileExpert:true});
            }else{
                this.setState({isMobileExpert:false});
            }
        };

    }

    loadMore = () => {
        //todo: spin icon in button and disable button while loading

        ReactGA.event({
            category: 'Results',
            action: 'Load More',
            label: (this.props.filters.page + 1).toString()
        });

        this.props.filterChanged({
            'id': 'page',
            'value': this.props.filters.page + 1
        });
    };

    toggleMode = () => {
        let newMode = 'basic';

        ReactGA.event({
            category: 'Results',
            action: 'Modus Changed',
            label: newMode
        });

        this.setState({isMobileExpert:false});
        this.props.changeMode(newMode);
    };

    componentDidMount() {
        if(this.state.isInitial){
            this.props.fetchDomains(this.props.filters,this.props.sorting);
            this.setState({isInitial:false});
        }
    }

    renderList() {
        return this.props.domains.map((domain) => {
            return (
                <DomainListItem domain={domain} key={domain.id} />
            );
        });
    }

    renderLoadMore() {
        return (
            <Button
                type={'dropdown'}
                classes={'btn--black btn--center btn--loadMore'}
                label={'Load More'}
                iconStart={['fal','sync-alt']}
                iconEnd
                onClick={this.loadMore}
            />
        )
    };

    render() {
        let showMore = false;
        if(this.props.domains.length % 30 === 0){
            showMore = true;
        }

        if (this.state.isMobileExpert) {
            return (
                <div className={'wrapper domain-list domain-list--'+this.props.mode}>
                    <div className={'domain-list__box domain-list__box--noResults'}>
                        <FontAwesomeIcon icon={['fal','cogs']} />
                        <h4>Sorry</h4>
                        <p>On mobile devices the expert mode is not yet available.</p>
                        <Button
                            type={'switch'}
                            classes={'btn--black btn--smaller'}
                            label={'Activate Basic Mode'}
                            iconStart={['fa','user']}
                            iconEnd
                            onClick={this.toggleMode}
                        />
                    </div>
                </div>
            )
        }

        if (this.props.domains.length === 0) {
            if (Object.keys(this.props.filters).length > 2) {
                return (
                    <div className={'wrapper domain-list domain-list--'+this.props.mode}>
                        <div className={'domain-list__box domain-list__box--noResults'}>
                            <FontAwesomeIcon icon={['fal','person-dolly-empty']} />
                            <h4>We've looked everywhere.</h4>
                            <p>But we did not find a domain for this search.</p>
                            <p>Please change your filters.</p>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className={'wrapper domain-list domain-list--'+this.props.mode}>
                        <div className={'domain-list__box domain-list__box--loadingResults'}>
                            <FontAwesomeIcon spin icon={['fal','sync-alt']} />
                            <h4>Preparing Results</h4>
                            <p>Your search results are getting prepared.</p>
                            <p>Please stand by.</p>
                        </div>
                    </div>
                )
            }
        }

        return (
            <div className={'wrapper domain-list domain-list--'+this.props.mode}>
                <div>
                    <DomainListHeader />
                    {this.renderList()}
                </div>
                {showMore ? this.renderLoadMore() : ''}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        domains: state.domains,
        filters: state.filters,
        options: state.options,
        mode: state.mode,
        completed: state.completed,
        sorting: state.sorting
    };
};

export default connect(mapStateToProps, {changeMode, filterChanged, fetchDomains, initialSetup})(DomainList);
