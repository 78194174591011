import React from 'react';
import { connect } from "react-redux";
import InputCheckbox from "./presentation/InputCheckbox";
import Button from "./presentation/Button";
import InputRadio from "./presentation/InputRadio";
import Sorter from "./presentation/Sorter";

require('../../stylesheets/components/filters/_Dropdown.scss');

class Dropdown extends React.Component {

    constructor() {
        super();

        this.state = {
            isHidden: true
        };

        this.toggleDropdown = this.toggleDropdown.bind(this);

    };

    componentDidUpdate(){
        if(!this.state.isHidden){
            document.addEventListener('mousedown', this.handleClick, false);
        }else{
            document.removeEventListener('mousedown', this.handleClick, false);
        }
    };

    handleClick = (e) => {
        if(this.node !== null){
            if(!this.state.isHidden && !e.target.classList.contains('.btn--dropdown') && e.target.closest('.dropdown__wrapper') == null){
                this.toggleDropdown();
            }
        }
    };

    toggleDropdown = () => {
        this.setState({
            isHidden: !this.state.isHidden
        })
    };

    renderGroupItems = (key,items) => {
        return items.map((item) => {

            if(item.type === 'checkbox') {
                return (
                    <li className={'dropdown__list__item'} key={key + '_' + item.id}>
                        <InputCheckbox
                            checked={item.checked}
                            id={item.id}
                            value={item.id ? item.id : 'com'}
                            label={item.label}
                            onChange={this.props.onChange}
                        />
                    </li>
                );
            } else if(item.type === 'sorter') {
                return (
                    <li className={'dropdown__list__item'} key={'sort_' + item.id}>
                        <Sorter
                            label={item.label}
                            id={item.mapping ? item.mapping : ''}
                        />
                    </li>
                )
            } else if(item.type === 'radio') {
                return (
                    <li className={'dropdown__list__item'} key={key + '_' + item.id}>
                        <InputRadio
                            id={item.id}
                            onChange=''
                            value={item.id ? item.id : 'com'}
                            label={item.label}
                        />
                    </li>
                );
            }

            return (
                <li className={'dropdown__list__item'} key={key + '_' + item.id}>
                    <Button id={item.id}>{item.label}</Button>
               </li>
            );
        });
    };

    renderGroups = () => {
        return this.props.options.map((option) => {
            let key = option.key;

            if(option.hasOwnProperty('headline')){
                return (
                    <div className={'dropdown__section'} key={key}>
                        <h4 className={'dropdown__title'}>{option.headline}</h4>
                        <ul className={'dropdown__list'}>
                            {this.renderGroupItems(key,option.items)}
                        </ul>
                    </div>
                );
            }else{
                return (
                    <div className={'dropdown__section'} key={key}>
                        <ul className={'dropdown__list'}>
                            {this.renderGroupItems(key,option.items)}
                        </ul>
                    </div>
                );
            }
        });
    };

    // comingSoon comingSoon--light
    renderDropdown = (isComingSoon) => {
        let className = 'dropdown__container--inner';
        if(isComingSoon === true){
            className = className + ' comingSoon comingSoon--light';
        }
        return (
            <div className={'dropdown__container--outer dropdown__container--' + this.props.dropdownStyle} ref={node => this.node = node}>
                <div className={className}>
                    {this.renderGroups()}
                </div>
            </div>
        )
    };

    render() {
        let iconStart = this.props.buttonIconStart;
        let iconEnd = ['fal','angle-down'];
        if (this.props.dropdownStyle === 'left') {
            iconStart = ['fal','angle-down'];
            iconEnd = this.props.buttonIconStart;
        }
        if (this.props.dropdownClass === 'doubleColumns') {
            iconEnd = ['fas','caret-down'];
        }

        return (
            <div className={'dropdown__wrapper dropdown--' + this.props.dropdownClass}>
                <Button
                    type={'dropdown'}
                    classes={this.props.buttonClasses}
                    label={this.props.buttonLabel}
                    iconStart={iconStart}
                    iconEnd={iconEnd}
                    onClick={this.toggleDropdown}
                />
                {!this.state.isHidden && this.renderDropdown(this.props.isComingSoon)}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {filters: state.filters};
};

export default connect(mapStateToProps)(Dropdown);
