import React from 'react';

require('../../stylesheets/components/layout/_Intro.scss');

class Intro extends React.Component {

    render() {
        return (
            <p className={'intro'}>
                Over <strong>2,000</strong> new exclusive domains added daily, starting at only <strong>$79</strong>!
            </p>
        )
    }
}
export default Intro;
