import React from 'react';
// import _ from 'lodash';

class BaseFilter extends React.Component {

    constructor() {
        super();

        //this.isValid = _.debounce(this.isValid, 500);
        this.changeHandler = this.changeHandler.bind(this);
    }

    changeHandler = event => {
        this.props.onChange(event); //call onChange event in parent
    };

    isValid(event) {
        //console.log('isValid', event);
    }
}

export default BaseFilter;
