import React from 'react';
import BaseFilter from './BaseFilter';
import InputCheckbox from './presentation/InputCheckbox';
import Label from "./presentation/Label";

class Excludes extends BaseFilter {

    render() {

        const checkBoxes = this.props.ids.map((checkbox) =>
            <InputCheckbox
                key={checkbox.id}
                id={checkbox.id}
                onChange={this.changeHandler}
                value={checkbox.value}
                label={checkbox.label}
                resetable
            />
        );

        return (
            <div className={'filter__item filter__item--checkbox filter__item--double'}>
                <div className={'filter__item__wrapper'}>
                    <Label
                        for
                        text={this.props.title}
                    />
                    <div className={'filter__columns'}>
                        {checkBoxes}
                    </div>
                </div>
            </div>
        );
    }
}

export default Excludes;
