import React from 'react';
import Logo from './partials/Logo';
import LogoSedo from './partials/LogoSedo';

require('../../stylesheets/components/layout/_Header.scss');

class Header extends React.Component {

    render() {
        return (
            <header className={'header'}>
                <div className={'wrapper'}>
                    <a href={"/"}><Logo /></a>
                    <a href={"https://sedo.com/"} target={"_blank"}>
                        <LogoSedo />
                    </a>
                </div>
            </header>
        )
    }
}
export default Header;
