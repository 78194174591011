import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {SORTING} from "./components/filters/Mapping";


import App from './components/App';
import reducers from './reducers';

let initialSettings = {
    completed: false,
    mode: 'basic',
    sorting: {
        sortBy: 'price',
        sortDirection: 'desc'
    },
    filters: {
        size: 30,
        page: 0
    },
    options: {
        columns: {
            'basic': [
                {id: 'domainIdn', mapping: 'domain', label: 'Domain Name', style: 'large', show: true, align: 'left'},
                {id: 'externalBacklinksCount', mapping: 'backlinks', label: 'Majestic BLs', show: true, align: 'right'},
                {id: 'searchVolume', mapping: 'volume', label: 'Google Search Vol, global', show: true, align: 'right'},
                {id: 'views', mapping: 'views', label: 'Parking Views, last 31d', show: true, align: 'right'},
                {id: 'auctionCurrentBid', mapping: 'price', label: 'Current Bid', style: 'extended', show: true, align: 'right'},
                {id: 'auctionEndDate', mapping: 'time', label: 'Time remaining', style: 'extended', show: true, align: 'right'},
            ],
            'expert': [
                {id: 'domainIdn', mapping: 'domain', label: 'Domain Name', style: 'large', show: true, align: 'left'},
                {id: 'externalBacklinksCount', mapping: 'backlinks', label: 'Majestic Backlinks', show: true, align: 'right'},
                {id: 'referringDomainsCount', mapping: 'referring', label: 'Majestic Domain Pop', show: true, align: 'right'},
                {id: 'searchVolume', mapping: 'volume', label: 'Google Srch Vol, global', show: true, align: 'right'},
                {id: 'cpc', mapping: 'cpc', label: 'Google CPC, global', show: true, align: 'right'},
                // {id: 'tld-count', label: 'TLDs count', show: true, align: 'right'},
                // {id: 'sedo-views', label: 'Sedo Views', show: true, align: 'right'},
                {id: 'views', mapping: 'views', label: 'Parking Views, last 31d', show: true, align: 'right'},
                {id: 'registrationDate', mapping: 'domainAge', label: 'Domain Age', show: true, align: 'right'},
                {id: 'auctionBidsCount', mapping: 'bids', label: 'Bids', show: true, align: 'right'},
                {id: 'auctionCurrentBid', mapping: 'price', label: 'Current bid', style: 'extended', show: true, align: 'right'},
                {id: 'auctionEndDate', mapping: 'time', label: 'Time remaining', style: 'extended', show: true, align: 'right'},
            ]
        },
        display: {
            'basic': [
                {
                    key: 'default',
                    headline: 'Default',
                    items: [
                        {id: 'domainIdn', label: 'Domain Name', style: 'large', type: 'checkbox'},
                        {id: 'externalBacklinksCount', label: 'Majestic BLs', type: 'checkbox'},
                        {id: 'searchVolume', label: 'Google Search Vol, global', type: 'checkbox'},
                        {id: 'auctionCurrentBid', label: 'Current Bid', type: 'checkbox'},
                        {id: 'auctionEndDate', label: 'Time remaining', type: 'checkbox'},
                    ]
                }
            ],
            'expert': [
                {
                    key: 'default',
                    headline: 'Default',
                    items: [
                        {id: 'domainIdn', label: 'Domain Name', type: 'checkbox'},
                        {id: 'externalBacklinksCount', label: 'Majestic Backlinks', type: 'checkbox'},
                        {id: 'referringDomainsCount', label: 'Majestic Domain Pop', type: 'checkbox'},
                        {id: 'searchVolume', label: 'Google Srch Vol, global', type: 'checkbox'},
                        {id: 'cpc', label: 'Google CPC, global', type: 'checkbox'},
                        {id: 'domainAge', label: 'Domain Age', type: 'checkbox'},
                        // {id: 'tld-count', label: 'TLDs count', type: 'checkbox'},
                        // {id: 'sedo-views', label: 'Sedo Views', type: 'checkbox'},
                        {id: 'auctionBidsCount', label: 'Bids', type: 'checkbox'},
                        {id: 'auctionCurrentBid', label: 'Current bid', type: 'checkbox'},
                        {id: 'auctionEndDate', label: 'Time remaining', type: 'checkbox'},
                    ]
                }
            ]
        },
        sorting: {
            'basic': [
                {
                    key: 'default',
                    headline: "Default",
                    items: [
                        {id: 'domainIdn', mapping: 'domain', label: 'Domain Name', style: 'large', type: 'sorter'},
                        {id: 'externalBacklinksCount', mapping: 'backlinks', label: 'Majestic BLs', type: 'sorter'},
                        {id: 'searchVolume', mapping: 'volume', label: 'Google Search Vol', type: 'sorter'},
                        {id: 'views', mapping: 'views', label: 'Parking Views, last 31d', type: 'sorter'},
                        {id: 'auctionCurrentBid', mapping: 'price', label: 'Current Bid', type: 'sorter'},
                        {id: 'auctionEndDate', mapping: 'time', label: 'Time remaining', type: 'sorter'}
                    ]
                }
            ],
            'expert': [
                {
                    key: 'default',
                    headline: "Default",
                    items: [
                        {id: 'domainIdn', mapping: 'domain', label: 'Domain Name', type: 'sorter'},
                        {id: 'externalBacklinksCount', mapping: 'backlinks', label: 'Majestic Backlinks', type: 'sorter'},
                        {id: 'referringDomainsCount', mapping: 'referring', label: 'Majestic Domain Pop', type: 'sorter'},
                        {id: 'searchVolume', mapping: 'volume', label: 'Google Srch Vol, global', type: 'sorter'},
                        {id: 'cpc', mapping: 'cpc', label: 'Google CPC, global', type: 'sorter'},
                        {id: 'views', mapping: 'views', label: 'Parking Views, last 31d', type: 'sorter'},
                        {id: 'domainAge', mapping: 'domainAge', label: 'Domain Age', type: 'sorter'},
                        {id: 'auctionBidsCount', mapping: 'bids', label: 'Bids', type: 'sorter'},
                        {id: 'auctionCurrentBid', mapping: 'price', label: 'Current bid', type: 'sorter'},
                        {id: 'auctionEndDate', mapping: 'time', label: 'Time remaining', type: 'sorter'}
                    ]
                }
            ]
        },
        export: [
            {
                key: 'export',
                items: [
                    {id: 'default', label: 'Export all Results', type: 'checkbox'},
                    {id: 'filtered', label: 'Export filtered Results', type: 'checkbox'},
                ]
            }
        ]
    }

};

const parseFiltersFromUrl = () => {

    let url = new URL(window.location.href);
    let sortParam = initialSettings.sorting;
    let filterParams = {};

    let parameters = url.search.slice(1).split('&');

    if (parameters !== '') {
        parameters.map((param) => {
            let parts = param.split('=');

            if (parts[0] === 'sort') {
                let sortingOptions = parts[1].split(',');

                sortParam.sortBy =
                    SORTING[sortingOptions[0].toUpperCase()] ?
                        sortingOptions[0].toLowerCase() :
                        'time';
                sortParam.sortDirection = sortingOptions[1] ? sortingOptions[1] : 'desc';
            } else if (parts[0]) {
                filterParams[parts[0].toUpperCase()] = decodeURI(parts[1]);
            }

            return false;
        });
    }

    return {
        filters: {...initialSettings.filters, ...filterParams},
        sorting: sortParam
    };
};


const store = createStore(reducers, {...initialSettings, ...parseFiltersFromUrl()}, applyMiddleware(thunk));

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);
