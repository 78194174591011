export default (state = [], action) => {
    if (action.type === 'SORT_CHANGED') {
        let sortDirectionValue = 'desc';
        if(state.sortBy === action.payload && state.sortDirection === 'desc'){
            sortDirectionValue = 'asc';
        }
        return {sortBy: action.payload, sortDirection: sortDirectionValue};
    }
    return state;
}
