import React from 'react';
import LogoSedo from './partials/LogoSedo';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

require('../../stylesheets/components/layout/_Footer.scss');

class Footer extends React.Component {

    render() {
        return (
            <footer className={'footer'}>
                <div className={'footer--lower'}>
                    <div className={'wrapper'}>
                        <a target={'_blank'} className={'sedo_logo'} href={process.env.REACT_APP_SEDO_URL}>
                            <LogoSedo />
                        </a>
                        <p className={'footer__copy'}>&copy; 1999 - {(new Date().getFullYear())} Sedo GmbH</p>
                        <ul className={'footer__navigation'}>
                            <li className={'footer__navigation__item'}>
                                <a target={'_blank'} href={process.env.REACT_APP_SEDO_URL + 'de/ueber-uns/impressum//'}>Imprint</a>
                            </li>
                            <li className={'footer__navigation__item'}>
                                <a target={'_blank'} href={process.env.REACT_APP_SEDO_URL + 'de/ueber-uns/policies-gmbh/datenschutzrichtlinien-der-sedo-gmbh/'}>Protecting your Privacy</a>
                            </li>
                            <li className={'footer__navigation__item'}>
                                <a target={'_blank'} href={process.env.REACT_APP_SEDO_URL + 'de/ueber-uns/policies-gmbh/'}>Policies</a>
                            </li>
                        </ul>

                        <ul className={'footer__social'}>
                            <li className={'footer__social__item'}>Find us on</li>
                            <li className={'footer__social__item'}>
                                <a target={'_blank'} href={"https://twitter.com/sedo"}>
                                    <FontAwesomeIcon icon={['fab','twitter']}/>
                                </a>
                            </li>
                            <li className={'footer__social__item'}>
                                <a target={'_blank'} href={"https://www.facebook.com/sedo"}>
                                    <FontAwesomeIcon icon={['fab','facebook']}/>
                                </a>
                            </li>
                            <li className={'footer__social__item'}>
                                <a target={'_blank'} href={"https://www.instagram.com/sedodomains/"}>
                                    <FontAwesomeIcon icon={['fab','instagram']}/>
                                </a>
                            </li>
                            <li className={'footer__social__item'}>
                                <a target={'_blank'} href={"https://www.youtube.com/user/SedoLLC"}>
                                    <FontAwesomeIcon icon={['fab','youtube']}/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        )
    }
}
export default Footer;
